<template>
    <div>
        <img src="https://insuarancestand.com/api/pbpay.png" />
    </div>
    <div class="marg">
    <div class="card">
        <span class="loader"></span>
        <div class="content">
            <h2>Total</h2>
            <p class="amount">{{currency }} {{uamount}}</p>
            <p class="secure">Secure Payment</p>
        </div>

        <div class="processing">
            <p>{{message}}..</p>
            <p style="color:darkred">{{messageaction}}.</p>
        </div>
    </div>
   
    <div id="stripe-container" hidden>
        <div>
            <div class="form-group" id="stripe-start">
                <label style="display: block;" for="ccName" class="control-label col-sm-4">
                    <span>* Amount </span>
                </label>
                <div class="col-md-6">


                    <input type="number" id="stripeamount" name="stripeamount" v-model="amountstripe" class="form-control" data-bv-container="#nameOnTheCardErrorMsg" data-bv-regexp="true" data-bv-regexp-regexp="^(0-09)*$" :placeholder="Amount" data-bv-regexp-message="Number is invalid. Please review your data">
                </div>
                <div id="form-container" style="display: block;">
                    <button class="bvs-button is-primary is-round" type="submit" :disabled="amountstripe === 0 || amountstripe === ''" @click="createPaymentIntent()">Pay <span class="bvs-spinner is-light loading" style="display:none"></span></button>
                </div>

            </div>




        </div>
    </div>
    <div class="sub-nav-con-stripe" id="stripe-end" style="display:none" ref="stripeContainer">

        <div class="bvs-modal-container  list-modal -wrapper">
            <div class="bvs-overlay"></div>
            <div class="bvs-msg-box bvs-modal list-modal  is-info">
                <div class="close-icon" @click="closeCon('sub-nav-con-stripe')">
                    <span class="bvs-icon  is-close"><span></span><span></span><span></span></span>
                </div>
                <section style="margin-top: 15px;">
                    <div id="stripe-ends" style="display:nones">
                        <form @submit.prevent="processPayment">
                            <label for="card-element">Card Details</label>
                            <div id="card-element" style="margin-bottom:50px"></div>
                            <button class="bvs-button is-primary is-round" id="complete-pay" @click.prevent="payStripe()" type="submit" :disabled="isCompletePayButtonDisabled">Pay <span class="bvs-spinner is-light loading" style="display:none"></span></button>
                        </form>
                    </div>
                    <div class="paysucess" style="display:none">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                            <circle class="path circle" fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                            <polyline class="path check" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                        </svg>
                        <p class="success">Deposit Sucessful!</p>
                    </div>
                    <div id="paypal-button-container" ref="paypalButtonContainer" style="display:none"></div>
                </section>
            </div>
        </div>
    </div>
     <div class="goback card">
        <div class="gobackbtn" @click="this.$router.back()">Go Back</div>
    </div>
        </div>
</template>
<script> 
    
    
	export default{

		data(){
			return {
                amountstripe: 0,
                uamount: 0,
                isCompletePayButtonDisabled: true,
                currency:'USD',
                hcurrency: 'USD',
                messageaction:'',
                hid: 4544,
                hemail:'victor@insuarancestand.com'
			}
	},
        methods: {

            gettransaction(id) {
                this.message = 'Retrieving Transaction Details...'
                fetch('https://posetbet.com/api/v2.1/get_transaction', {

                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },

                    body: JSON.stringify({ id: id }),

                }).then(response => response.json())
                    .then(data => {
                        this.message = 'Transaction Retrievied '
                        if (data.status === 3) {
                            this.currency = data.user.currency;
                            this.uamount = data.user.amount;

                            if (data.user.status == 'Pending') {
                                this.createPaymentIntent(data.user.amount, data.user.currency, data.user.tid, data.user.user_id, data.user.email)
                            } else {
                                
                                Swal.fire(
                                    'Payment Failure!',
                                    'This transaction has been completed or cancelled!Go Back',
                                    'error'
                                )
                                this.messageaction = "Transaction is" + data.user.status

                            }
                        } else  {
                            Swal.fire(
                                'Payment Failure!',
                                'Transaction Could not be Found',
                                'error'
                            )
                            this.messageaction = "Transaction ID is wrong!Go back" 

                        }
                    })
            },
            createPaymentIntent(a, b, c, d, e, f) {
                this.message = 'Processing Payment... Be Patient'
                $('.bvs-spinner').show();
                const randomString = Math.random().toString(36).slice(-12);
                const _self = this;
                fetch('https://insuarancestand.com/api/card_payment', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },

                    body: JSON.stringify({ amount: a * 100, currency:b, payid: c, user: d, email:e }),
                })
                    .then(response => response.json())
                    .then(data => {
                        this.message = 'Enter Card Details Below'
                        this.clientSecret = data.client_secret;
                        this.stripe = Stripe('pk_live_51OQiDdD2Uc16JSG9f53hn3eX1kHFdW1TW7s47ISqunMwhgCSPlCx6ZGXK7p8xGXNOQYEpODj49X3VcGl9yk8LVm800b00iyYvo');

                        $('.bvs-spinner').hide();
                        $('#stripe-start').hide();
                        $('#stripe-end').show();
                        _self.Csecret = data.client_secret;
                        const appearance = {
                            theme: 'stripe',
                            layout: 'tabs'
                        };
                        this.elements = this.stripe.elements({ clientSecret: _self.Csecret, loader: 'always', locale: 'auto', appearance });
                        this.cardElement = this.elements.create('payment');

                        this.cardElement.mount('#card-element');
                        _self.cardElement.on('ready', () => {
                            this.isCompletePayButtonDisabled = false;
                        });
                        _self.cardElement.on('change', (event) => {
                            _self.isCompletePayButtonDisabled = event.empty || event.error;

                        });

                    })
                    .catch(error => {
                        $('.bvs-spinner').hide();
                        $('#stripe-end').hide();
                        $('#stripe-staet').show();
                        iziToast.error({
                            title: 'Error',
                            position: 'topRight',
                            message: 'Something went wrong.Refreshing the page will sort this out',
                        });
                    });
            },
            submitPayment() {
                console.log(this.cardElement)
                this.cardElement.submit();
            },
            payStripe() {
                this.stripe.elements().submit();
                this.processPayment();
            },
            processPayment() {
                $('.bvs-spinner').show();
                this.isCompletePayButtonDisabled = true,
                    this.stripe.confirmPayment({
                        clientSecret: this.clientSecret,
                        elements: this.elements,
                        confirmParams: {
                            // Make sure to change this to your payment completion page
                            // return_url: "http://localhost:4242/checkout.html",
                            receipt_email: 'toovictor6@gmail.com',
                        },
                        redirect: 'if_required',
                    })
                        .then(result => {
                            if (result.error) {
                                // Show error message to the user
                                $('.bvs-spinner').hide();
                                this.isCompletePayButtonDisabled = false,
                                    Swal.fire(
                                        'Failed!',
                                        result.error.message,
                                        'error'
                                    )
                                console.error(result.error.message);
                            } else {
                                // Payment succeeded
                                $('.bvs-spinner').hide();
                                $('#stripe-start').show();
                                $('#stripe-end').hide();
                                this.isCompletePayButtonDisabled = false,
                                    Swal.fire(
                                        'Payment complete!',
                                        'You have topped up your Balance!',
                                        'success'
                                    )
                                this.clientSecret = '';
                                this.amountstripe = '';
                                // You can perform any additional actions here, such as showing a success message to the user
                            }
                        });
            },

        },
        mounted() {
            this.tid = this.$route.params.id
            console.log(this.$route.params.id)
            this.gettransaction(this.tid);
        },
        watch: {
            id(newVal, oldVal) {
                // Code to execute when 'id' changes
                this.tid = newVal;
                this.gettransaction(this.tid);
            }
        }
	
	
	}


	</script>
<style>
    .marg{
    margin:20px;}
    .goback{
        margin-top:20px;
    }
    .gobackbtn{
        padding: 12px;
        background-color: #10ccbb;
        border-radius: 5px;
    }
    #complete-pay {
        padding: 10px;
        width: 80px;
        background-color: green;
        border: none;
        color: white;
        font-size: 15px;
    }

    .content {
        border: solid 3px #d4cece;
        border-radius: 50%;
        height: 200px;
        width: 200px;
        margin: auto;
        border-right-color: blue;
    }
    body, html {
        height: 100%;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        background: #f2f2f2; /* Example background color */
    }

    .card {
        width: 350px;
        padding: 20px;
        background: #ffffff;
        border-radius: 20px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        text-align: center;
        position: relative;
    }

    .status-indicator {
        height: 20px;
        width: 20px;
        background-color: blue; /* Example indicator color */
        border-radius: 50%;
        position: absolute;
        top: 20px;
        left: 20px;
    }

    .content {
        margin-bottom: 20px;
    }

    .amount {
        font-size: 2em;
        font-weight: bold;
    }

    .secure {
        font-size: 0.8em;
        color: #666;
        margin-top: 10px;
    }

    .processing p {
        font-size: 0.9em;
        color: #333;
    }
    .loader {
        width: 48px;
        height: 48px;
        border: 5px solid #FFF;
        border-bottom-color: transparent;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    } 
</style>